<template>
  <div class="df-width case">
    <div class="case-title">经典案例</div>
    <div class="case-desc">我们能帮你解决问题</div>
    <div class="case-pro-title">旗帜大数据智慧党建系统</div>
    <div class="case-pro-content">
      “旗帜大数据智慧党建系统”是一款通过 “ 碎片化学习 、 系统性认知 、 事务化管理 、 精细化服务 ” 四大核心应用的，为组织、街道、机关、大型院校以及行业协会解决团队的自身建设与为民服务的综合性应用系统。
      系统融合了移动互联网、云计算、大数据、新一代网页语言等技术，实现了网站+PC+手机应用全平台应用需求。
    </div>
    <div class="case-banner">
      <el-carousel indicator-position="outside" :interval="5000" arrow="never" :height="bannerHeight + 'px'">
        <el-carousel-item v-for="(item, i) in banner" :key="i">
          <el-image :src="item" class="index-banner-pic" fit="cover"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProDetail',
  data () {
    return {
      bannerHeight: 436,
      banner: [
        require('@/assets/images/detail.png'),
        require('@/assets/images/detail.png')
      ]
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
  .case {
    padding: 60px 0;
  }
  .case-title {
    text-align: center;
    color: var(--color-text);
    font-size: 48px;
  }
  .case-desc {
    color: var(--color-text);
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 60px;
    text-align: center;
  }
  .case-pro-title {
    color: var(--color-text);
    font-size: 30px;
    text-align: center;
  }
  .case-pro-content {
    color: var(--color-text);
    font-size: 14px;
    text-align: center;
    line-height: 24px;
    margin: 30px auto 60px;
    width: 802px;
  }
  .case-banner {
    width: 802px;
    margin: 0 auto;
  }
  .index-banner-pic {
    width: 100%;
  }

  :deep(.el-image__inner) {
    width: 100%;
    /*height: auto;*/
  }

  :deep(.el-carousel__indicators), :deep(.el-carousel__indicators--horizontal), :deep(.el-carousel__indicators--outside) {
    margin-top: 50px;
  }
  :deep(.el-carousel__indicators--outside > .el-carousel__indicator--horizontal > .el-carousel__button) {
    background-color: #C81125;
    width: 10px;
    height: 10px;
    border-radius: 5px;
  }
  :deep(.el-carousel__indicators--outside > .is-active > .el-carousel__button) {
    background-color: #C81125;
    width: 30px;
    height: 10px;
    border-radius: 5px;
  }
</style>
